import React, { Component } from 'react';
import * as THREE from 'three';
import LoadingOverlay from './loadingoverlay';

import { CreateSphere } from "../threejs/sphere";
import { CreateCube} from "../threejs/cube";

let then = 0;
let cube;

export default class SceneComponent extends Component {
  constructor(props) {
    super(props);
    this.scrollTop = 0;
    this.state = {
      loaded: false
    };
  }


  componentDidMount = () => {
      this.setupRenderer();
      this.setupScene();
      this.setupCamera();
      this.setupLights();
      this.buildScene()
      .then(() => {
        this.animate();
        this.setState({
          loaded: true
        });
      });      
  }

  setupCamera = () => {
    let { innerWidth: width, innerHeight: height } = window;
    this.camera = new THREE.PerspectiveCamera(45, (width / 2.0) / (height / 2.0), 10, 1000);
    this.camera.position.set(0, 0, 20);
  }

  setupRenderer = () => {
    let { innerWidth: width, innerHeight: height } = window;
    
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: false,
      preserveDrawingBuffer: false
    });

    const pixelRatio = Math.max(2, window.devicePixelRatio);
    this.renderer.setPixelRatio(pixelRatio);
    this.renderer.setSize(width / 2.0, height / 2.0);
    // Setup resize events
    window.addEventListener('resize', () => this._resize());
    window.addEventListener('orientationchange', () => this._resize());
  }

  setupScene = () => {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xffffff);
  }

  buildScene = () => {
    return new Promise((resolve) => {
      const sphere = CreateSphere();
      this.scene.add( sphere );

      cube = CreateCube();
      this.scene.add(cube);
      resolve();
    });
  }

  setupLights = () => {
    
  }

  animate = (now) => {
    now *= 0.01;
    const delta_ms = now - then;
    then = now;

    
    cube.rotation.y += 0.005;

    // Start loop again
    requestAnimationFrame(this.animate);
    this.renderer.render(this.scene, this.camera);
  }


  render() {
    return (
      <div className="three-container" ref={element => this.containerElement = element}>
        <LoadingOverlay loaded={this.state.loaded} />        
        <div className="three-scroller" ref={element => this.scrollElement = element} />
        <canvas id="three-canvas" ref={element => this.canvas = element} />
      </div>
    )
  }

  _resize() {
    let { innerWidth: width, innerHeight: height } = window;
    this.renderer.setSize(width, height);
    this.camera.updateProjectionMatrix();
  }

}
